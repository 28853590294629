import React from 'react'
import CrossIcon from '../../assets/images/CrossIcon'
import BlueCheck from '../../assets/images/BlueCheck'
import ScrollAnimation from 'react-animate-on-scroll'
const positiveArr = [
    {
        title: 'Scalable Remote Workforce'
    },
    {
        title: 'Elite Talent Pool'
    },
    {
        title: 'Full Ownership and Control'
    },
    {
        title: 'Dedicated Project Management'
    },
    {
        title: 'Flexibility and Freedom'
    },
    {
        title: 'No Contract. Cancel Anytime.'
    },
]
const negativeArr = [
    {
        title: 'No Lengthy Hiring Processes'
    },
    {
        title: 'Consistent Availability of Talent'
    },
    {
        title: 'Independent of Agency Timelines for Updates'
    },
    {
        title: 'Avoid Large Upfront Costs'
    },
    {
        title: 'No Endless Waiting'
    },
]
const BusinessGrow = () => {
    const boxShadow = {
        boxShadow: '0 4px 55px rgba(0, 0, 0, 0.07)',
    }
    return (
        <div className='pb100'>
            <div className="container">
                <ScrollAnimation animateIn='backInLeft' animateOnce={true} >
                <h2 className='xl:w-[75%] w-full mx-auto text-center text-black fs40'>We Help You Grow Your Business Without Growing Your Team</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInRight' animateOnce={true} >
                <h6 className='xl:w-[75%] w-full mx-auto text-center text-black fs24 font-semibold mt-2'>
                Effortless Scaling with Pixel Pace Technologies
                </h6>
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInDown' animateOnce={true} >
                <p className='fs22 max-w-[880px] w-full mx-auto text-center text-black mt-[30px] mb-[60px] max-[1370px]:mt-[16px] max-[1370px]:mb-[30px]'>
                At Pixel Pace Technologies, we streamline your business operations by providing top-tier outsourcing solutions in design and development. Eliminate the common hurdles of traditional hiring and onboarding with our flexible, on-demand services designed to accelerate your growth without expanding your in-house team.
                </p>
                </ScrollAnimation>
                
                <div className='flex justify-center gap-8 max-[575px]:flex-wrap'>
                    <ScrollAnimation animateIn='backInLeft' animateOnce={true}  className='bg-[#CAFFFF] flex flex-col gap-7 p-10 rounded-[5px] lg:w-1/3 w-full max-[1680px]:p-8 max-[1560px]:p-6 max-[1570px]:gap-4 max-[1370px]:gap-3 buisnessBx' style={boxShadow}>
                    <ScrollAnimation animateIn='backInUp' animateOnce={true} >
                        <h6 className='text-black fs18 font-semibold'>Advantages of Partnering with Us:</h6>
                    </ScrollAnimation>

                        {
                            positiveArr.map((positiveD, index) => (
                                <ScrollAnimation animateIn='backInUp' animateOnce={true}   className='flex items-start gap-4 max-[1370px]:gap-2 '>
                                    <BlueCheck className='max-w-[20px] w-full max-[1370px]:max-w-[16px]' iconClr='#0FE3E3' />
                                    <p className='fs22 text-[#000] w-full' key={index}>{positiveD.title}</p>
                                </ScrollAnimation>
                            ))
                        }
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='backInRight' animateOnce={true}  className='buisnessBx flex flex-col transition-all duration-300 gap-7 p-10 rounded-[5px] lg:w-1/3 w-full max-[1680px]:p-8 max-[1560px]:p-6 max-[1570px]:gap-4 max-[1370px]:gap-3' style={{transition: "all 0.3s ease-in-out"}}>
                    <ScrollAnimation animateIn='backInUp' animateOnce={true} >
                        <h6 className='text-black fs18 font-semibold'>Eliminate Common Outsourcing Headaches:</h6>
                    </ScrollAnimation>
                        {
                            negativeArr.map((negativeD, index) => (
                                <li key={index}  className='flex items-start gap-4 max-[1370px]:gap-2'>
                                    <CrossIcon className='max-w-[20px] w-full max-[1370px]:max-w-[14px]' iconClr='#FF0C04' />
                                    <p className='text-[#000] w-full fs22' key={index}>{negativeD.title}</p>
                                </li>
                            ))
                        }
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    )
}

export default BusinessGrow
