import React from 'react'
import ContactUs from './ContactUs'
import BrowserTabTitle from '../../components/browserTabTitle'
const ContactUsPage = () => {
  return (
    <div>
      <BrowserTabTitle tabTitle={'Contact us - Pixel Pace Technologies'}/>
      <ContactUs/>
    </div>
  )
}

export default ContactUsPage
