import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/webDesignBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';

const dataArr = [
  {
    subtitle: "Expertise in Aesthetic Innovation:",
    title: "Our team of seasoned designers is dedicated to crafting captivating websites that not only look great but also function flawlessly. We bridge the gap between your business vision and reality, ensuring every design element aligns with your strategic goals.",
  },
  {
    subtitle: "Engagement Driven Designs:",
    title: "We focus on creating designs that engage your audience and keep them coming back. Whether it's through user-friendly interfaces, interactive elements, or responsive layouts, our designs are tailored to meet the needs of your target audience.",
  },
  {
    subtitle: "Seamless Integration with Business Goals: ",
    title: "We don’t just create beautiful websites; we create platforms that serve as a key tool in your marketing strategy. Our designs are developed to enhance your online presence and help achieve your business objectives.",
  },
]
const WebDesignSer = () => {
  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});
  return (
    <div className="serviceBg py100" style={serviceBgStyle}>
      <div className='container'>
        <BrowserTabTitle tabTitle={'Professional Web Design Services | Pixel Pace Technologies'} metaDescription={`Transform your business with Pixel Pace Technologies' professional web design services. Our seasoned team excels in creating visually stunning and functionally superior websites that captivate customers and amplify your brand identity. Book a free consultation today to bring your business vision to life!`} />
        <ServicePage title="Web Design Services at Pixel Pace Technologies" subTitle='Crafting Visually Stunning and Functional Websites' detail="At Pixel Pace Technologies, we understand that exceptional web design is the cornerstone of modern digital marketing. Our web design services are centered on elevating your brand's identity through innovative designs that immerse customers in enriching experiences." />

        <div className='pt100' ref={ref}>
        <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__backInLeft' : ''}`}>Why Choose Pixel Pace for Web Design?</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__backInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default WebDesignSer
