import React from 'react'
import requestBackImage from '../../assets/images/request_back.png';
import bookAppointIcon from '../../assets/images/bookAppoinmentIcon.png';
import Button from '../../components/button/Button';
import AppointmentBtn from '../../assets/images/AppointmentBtn';
import ScrollAnimation from 'react-animate-on-scroll';

const BookAppoinment = () => {
    return (
        <section className='bg-cover bg-no-repeat pt-20 pb-12 max-[1680px]:pt-12 max-[1680px]:pb-7 max-[1370px]:pt-8' style={{ backgroundImage: `url(${requestBackImage})` }}>
            <div className="container flex md:justify-between justify-center items-center lg:gap-0 md:gap-x-4 md:flex-nowrap flex-wrap gap-4">
                <div className='md:w-[62%]'>
                    <ScrollAnimation animateIn='backInLeft' animateOnce={true}  >
                        <h2 className='interFont fs45 text-white font-bold mb-7 max-[1500px]:mb-4 max-[1370px]:mb-2 max-[575px]:text-center'>Let’s Request a Schedule For
                            Free Consultation</h2>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='backInLeft' animateOnce={true} >
                        <a className='flex gap-3 items-center max-[575px]:justify-center' href="tel:+61 417 205 778">
                            <img src={bookAppointIcon} alt="" className='w-[75px] max-[1680px]:w-[55px] max-[1370px]:w-[40px] max-[575px]:w-[30px]' />
                            <p className='interFont fs24 text-white font-semibold'>Call for More Info: +61 417 205 778</p>
                        </a>
                    </ScrollAnimation>
                </div>
                <ScrollAnimation animateIn='backInRight' animateOnce={true} >
                <Button shadowClr='rgba(0,0,0,0.5)' btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" btnTxt="Book an Appointment" className="bg-[#DFFFFF] text-[#003D3D] w-fit py-6 px-8 fs24 font-semibold gap-5 max-[1680px]:gap-3 max-[1500px]:py-5 max-[1500px]:px-6 max-[1370px]:px-4 max-[1370px]:py-4 max-[1370px]:gap-2 before:bg-white max-[575px]:p-3" btnIcon={<AppointmentBtn className='w-[41px] max-[1680px]:w-[32px] max-[1370px]:w-[25px] max-[575px]:w-[22px]' />}/>
                </ScrollAnimation>
                
            </div>
        </section>
    )
}

export default BookAppoinment
