import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../components/button/Button'
import './index.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import { useInView } from 'react-intersection-observer';
const tabsData = [
    {
        id: "pills1",
        label: "Web Development",
        link: '/web-development',
        imageSrc: require("../../../assets/images/service1.png"),
        subHeading: "Our Services",
        heading: "Complete Web Development for your Business",
        detail: "We excel in the art of Web Development. Our skilled team of developers transforms ideas into dynamic digital realities. Whether you need a captivating website, a powerful web application, or a user-friendly portal, we leverage the latest technologies to create tailored solutions that align with your goals.",
        animate1: "backInLeft",
        animate2: "backInRight",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Custom shortcodes",
            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Data Analytics",
            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "IT Consultancy",
            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Data security",
            },
        ]
    },
    {
        id: "pills2",
        label: "Web Design",
        imageSrc: require("../../../assets/images/service2.png"),
        subHeading: "Our Services",
        link: '/web-design',
        heading: "Complete Web Design",
        detail: "We believe that design is at the heart of every compelling digital experience. Our Web Design service is dedicated to creating visually captivating and user-centric interfaces. Our team of creative designers combines aesthetics with functionality, crafting websites that not only leave a memorable impression but also provide intuitive navigation for visitors.",
        animate1: "",
        animate2: "",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Custom shortcodes",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Responsive Layout",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Easily Editable Theme",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Beautiful User Experience",
            },
        ]
    },
    {
        id: "pills3",
        label: "Wordpress",
        imageSrc: require("../../../assets/images/service3.png"),
        subHeading: "Our Services",
        link: '/wordpress',
        heading: "Wordpress Website",
        detail: "We harness the power of WordPress to empower your digital presence. Our WordPress service is designed to provide you with a versatile and customizable platform for managing your online content. From seamless website setup and tailored design to optimizing performance and ensuring security, we offer a comprehensive suite of services that cater to your unique needs.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Custom shortcodes",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Responsive Layout",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Easily Editable Theme",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Beautiful User Experience",

            },
        ]
    },
    {
        id: "pills4",
        label: "Shopify",
        link: '/shopify',
        imageSrc: require("../../../assets/images/service4.png"),
        subHeading: "Our Services",
        heading: "Shopify Website",
        detail: "Our team is dedicated to transforming your business into a successful online store. Whether you're just starting or looking to optimize your existing setup, our Shopify experts provide end-to-end solutions. From seamless store setup and customized designs to secure payment gateways and user-friendly navigation, we ensure your products take center stage.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Custom shortcodes",


            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Responsive Layout",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Easily Editable Theme",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Beautiful User Experience",

            },
        ]
    },
    {
        id: "pills5",
        label: "Marketing",
        link: '/marketing',
        imageSrc: require("../../../assets/images/service5.png"),
        subHeading: "Our Services",
        heading: "Complete Digital Marketing",
        detail: "Our team is dedicated to propelling your brand forward in the digital landscape. Through strategic Search Engine Optimization (SEO), targeted social media campaigns, and compelling content marketing, we ensure your message reaches your desired audience. We understand that successful marketing is about more than just visibility; it's about creating meaningful connections.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Custom shortcodes",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Responsive Layout",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Easily Editable Theme",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Beautiful User Experience",

            },
        ]
    },
    {
        id: "pills6",
        label: "UI UX Design",
        link: '/ux-ui-design',
        imageSrc: require("../../../assets/images/service6.png"),
        subHeading: "Our Services",
        heading: "Complete UI UX Design",
        detail: "We specialize in elevating digital experiences through our UX/UI Design expertise. With an unwavering focus on user-centric design, we create interfaces that seamlessly merge aesthetics and functionality. Our team crafts intuitive user journeys, ensuring every interaction is both engaging and efficient.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Custom shortcodes",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Responsive Layout",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Easily Editable Theme",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Beautiful User Experience",

            },
        ]
    },
    {
        id: "pills7",
        label: "NFT Website",
        imageSrc: require("../../../assets/images/service7.png"),
        subHeading: "Our Services",
        heading: "Complete NFT Project",
        detail: "Our NFT service is your gateway to the future of digital ownership. With us, you can mint NFTs, providing indisputable proof of ownership and authenticity for your digital creations, whether they're art, music, or collectibles. We offer a secure and user-friendly experience, empowering you to tap into the limitless potential of the NFT ecosystem.",
        serviceLiArr: [
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Custom shortcodes",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Responsive Layout",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Easily Editable Theme",

            },
            {
                img: require("../../../assets/images/tick_icon.png"),
                liTitle: "Beautiful User Experience",

            },
        ]
    },
    // Define other tabs similarly with imageSrc and textLines
];
const ServicesBox = () => {

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const [activeTab, setActiveTab] = useState(tabsData[0].id);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };


    return (
        <section className='pb100 min-[1690px]:pt-[200px] min-[1370px]:pt-[165px] min-[1200px]:pt-[150px] md:pt-[130px] pt-[175px] bg-[#006363]'>
            <div className="container">
                <ScrollAnimation animateIn='backInLeft' animateOnce={true} >
                    <span className='block text-center fs20 font-semibold text-white'>Services we provide</span>
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInRight' animateOnce={true} >
                    <h2 className='fs40 text-center font-bold md:w-[70%] mx-auto text-white pt-1 mb-3 leading-tight'>We’ve got everything you need to <span className='text-[#0FE3E3]'>launch and grow</span> your business</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInUp' animateOnce={true} >
                    <p className='fs16 text-white xl:w-[80%] w-full mx-auto text-center pb80 leading-normal'>At Pixel Pace Technologies, we offer a comprehensive suite of web development and digital marketing services to meet the diverse needs of modern businesses. Our expertise spans:</p>
                </ScrollAnimation>
                <div>
                    <div className="flex justify-between md:flex-nowrap flex-wrap min-[1680px]:mb-16 mb-8 md:gap-0 gap-2">
                        {tabsData.map((tab) => (
                            <ScrollAnimation animateIn='backInUp' animateOnce={true} key={tab.id}>
                                <button
                                    className={`text-white pb-7 fs24 font-normal max-[1680px]:pb-3 max-[1370px]:pb-1 ${activeTab === tab.id ? "border-b-2 border-white font-semibold opacity-100" : "opacity-80"}`}
                                    id={`${tab.id}Tab`}
                                    onClick={() => handleTabClick(tab.id)}
                                    type="button"
                                    role="tab"
                                    aria-controls={tab.id}
                                    aria-selected={activeTab === tab.id}
                                >
                                    {tab.label}
                                </button>
                            </ScrollAnimation>
                        ))}
                    </div>
                    <div className='min-[1680px]:mb-16 mb-6'>
                        {tabsData.map((tab) => (
                            <div
                                className={`servicesWrap flex justify-between items-center md:flex-row flex-col md:gap-0 gap-6 tabPane transition-opacity duration-300 ${activeTab === tab.id ? "opacity-100" : "opacity-0 hidden"
                                    }`}
                                id={tab.id}
                                role="tabpanel"
                                aria-labelledby={`${tab.id}Tab`}
                                tabIndex="0"
                                key={tab.id}
                            >
                                <div ref={ref} className={`serviceBox min-[1690px]:h-[585px] min-[1370px]:h-[400px] md:h-[360px] h-[300px] ${inView ? 'animate__animated animate__backInLeft' : ''}`}>
                                    <img className='w-full h-full min-[1690px]:object-fill object-contain md:object-center object-left' src={tab.imageSrc} alt={`${tab.label} Image`} />
                                </div>
                                <div ref={ref} className={`serviceBox min-[1690px]:pr-12 pr-0 ${inView ? 'animate__animated animate__backInRight' : ''}`}>
                                    <h6 className=' fs20 font-semibold text-white'>{tab.subHeading}</h6>
                                    <h3 className=' fs40 font-bold leading-normal text-white mt-2 mb-5 max-[1500px]:leading-tight max-[1500px]:mb-4 max-[1370px]:mb-2 max-[1370px]:mt-1'>{tab.heading}</h3>
                                    <p className=' fs18 leading-normal text-white'>{tab.detail}</p>
                                    <div className='flex gap-y-3 flex-wrap min-[1690px]:mt-9 mt-6 max-[1370px]:mt-4'>
                                        {
                                            tab.serviceLiArr?.map((serviceLiData, index) => (
                                                <div className=' flex gap-[10px] items-center xl:first-line:max-w-[250px] w-full max-[1370px]:gap-[7px] max-w-[195px] max-[575px]:max-w-full' key={index}>
                                                    <div className='w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#04B0B0] max-[1370px]:w-[25px] max-[1370px]:h-[25px]'>
                                                        <img src={serviceLiData.img} className='max-[1370px]:w-[12px]' alt="" />
                                                    </div>

                                                    <p className='text-white fs18'>{serviceLiData.liTitle}</p>
                                                </div>
                                            ))
                                        }
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                    <ScrollAnimation animateIn='backInUp' animateOnce={true} >
                        <Button shadowClr='rgba(0, 0, 0, 0.5)' btnLink={tabsData.find((tab) => tab.id === activeTab)?.link} btnTxt="Read more" className="readMore bg-white fs18 text-[#006363] w-fit px-8 py-5 mx-auto font-medium max-[1500px]:px-6 max-[1500px]:py-4 max-[1370px]:px-4 max-[1370px]:py-3 max-[575px]:px-4 before:bg-black hover:text-white" />
                    </ScrollAnimation>

                </div>
            </div>
        </section>
    );
};

export default ServicesBox;
