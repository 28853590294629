import React , {useState} from 'react'
import './portfolio.scss'
import Button from '../../../components/button/Button'
import PortfolioArrow from '../../../assets/images/PortfolioArrow'
import PhoneIcon from '../../../assets/images/PhoneIcon'
import zoomIcon from '../../../assets/images/zoom_in.png'
import previewIcon from '../../../assets/images/arrow_insert.svg'
import { Link, useLocation } from 'react-router-dom'
import LightBox from './LightBox'
import ScrollAnimation from 'react-animate-on-scroll';
const PortfolioWrap = () => {
    const location = useLocation();
    const isSpecificPage = location.pathname === '/Portfolio';

    let portfolioArr = [
        {
            imgSrc: require('../../../assets/images/portfolio11.jpg'),
            imgSrcShadow: require('../../../assets/images/portfolio11.jpg'),
            animate: 'backInUp',
            link: 'https://happy-girls.vercel.app/',
        },
        {
            imgSrc: require('../../../assets/images/portfolio12.jpg'),
            imgSrcShadow: require('../../../assets/images/portfolio12.jpg'),
            animate: 'backInLeft',
            link: 'https://rubber-ducky-sepia.vercel.app/',
        },
        {
            imgSrc: require('../../../assets/images/portfolio13.jpg'),
            imgSrcShadow: require('../../../assets/images/portfolio13.jpg'),
            animate: 'backInRight',
            link: 'https://c5-microsite.vercel.app/',
        },
        {
            imgSrc: require('../../../assets/images/portfolio18.jpg'),
            imgSrcShadow: require('../../../assets/images/portfolio18.jpg'),
            animate: 'backInRight',
            link: 'https://super-alpha-five.vercel.app/',
        },
        {
            imgSrc: require('../../../assets/images/portfolio16.jpg'),
            imgSrcShadow: require('../../../assets/images/portfolio16.jpg'),
            animate: 'backInRight',
            link: 'https://xenodochial-hamilton-2b1d36.netlify.app/',
        },
        {
            imgSrc: require('../../../assets/images/portfolio19.jpg'),
            imgSrcShadow: require('../../../assets/images/portfolio19.jpg'),
            animate: 'backInUp',
            link: 'https://cyber-fighter.vercel.app/',
        },
        
    ];
    if (isSpecificPage) {
        portfolioArr = [
            ...portfolioArr,
            {
                imgSrc: require('../../../assets/images/portfolio17.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio17.jpg'),
                animate: 'backInUp',
                link: 'https://first-coast.vercel.app/',
            },
            {
                imgSrc: require('../../../assets/images/portfolio3.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio3.jpg'),
                animate: 'backInLeft',
                link: 'https://dark-illusion.vercel.app/',
            },
            {
                imgSrc: require('../../../assets/images/portfolio15.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio15.jpg'),
                animate: 'backInRight',
                link: 'https://game-up-africa.vercel.app/',
            },
            {
                imgSrc: require('../../../assets/images/portfolio4.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio4.jpg'),
                animate: 'backInRight',
                link: 'https://eye-care-nine.vercel.app/',
                isWhite: true,
            },
             {
                imgSrc: require('../../../assets/images/portfolio14.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio14.jpg'),
                animate: 'backInUp',
                link: 'https://magic-mashrooms.vercel.app/',
                isWhite: true,
            },
          
            {
                imgSrc: require('../../../assets/images/portfolio6.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio6.jpg'),
                animate: 'backInLeft',
                link: 'https://top-max.vercel.app/',
                isWhite: true,
            },
            {
                imgSrc: require('../../../assets/images/portfolio5.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio5.jpg'),
                animate: 'backInDown',
                link: 'https://cheat-layers.vercel.app/',
                isWhite: true,
            },
            {
                imgSrc: require('../../../assets/images/portfolio8.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio8.jpg'),
                animate: 'backInUp',
                link: 'https://garniche.vercel.app/',
                isWhite: true,
            },
            {
                imgSrc: require('../../../assets/images/portfolio7.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio7.jpg'),
                animate: 'backInRight',
                link: 'https://decoria.vercel.app/',
                isWhite: true,
            },
            {
                imgSrc: require('../../../assets/images/portfolio2.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio2.jpg'),
                animate: 'backInUp',
                link: 'https://creative-rabel.vercel.app/',
            },
            {
                imgSrc: require('../../../assets/images/portfolio10.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio10.jpg'),
                animate: 'backInRight',
                link: 'https://logi-truck.vercel.app/',
            },
            {
                imgSrc: require('../../../assets/images/portfolio9.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio9.jpg'),
                animate: 'backInLeft',
                link: 'https://socialized-globe.vercel.app/',
                isWhite: true,
            },
            {
                imgSrc: require('../../../assets/images/portfolio1.jpg'),
                imgSrcShadow: require('../../../assets/images/portfolio1.jpg'),
                animate: 'backInRight',
                link: 'https://collection-name.vercel.app/',
            },
        ];
    }

    const btnWithPort = "bg-[#006363] w-fit py-6 px-8 mx-auto fs22 text-[#fff] gap-4 font-semibold max-[1500px]:gap-3 max-[1500px]:py-5 max-[1500px]:px-6 max-[1370px]:px-4 max-[1370px]:py-4 max-[1370px]:gap-2";
    const btnWithoutPort = "bg-[#006363] w-fit py-6 px-8 mx-auto fs22 text-[#fff] flex-row-reverse gap-4 font-semibold max-[1500px]:gap-3 max-[1500px]:py-5 max-[1500px]:px-6 max-[1370px]:px-4 max-[1370px]:py-4 max-[1370px]:gap-2"

    const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleZoomIconClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

    return (
        <>
        {
            lightboxOpen && 
            <LightBox 
              images={portfolioArr} 
              selectedImage={selectedImage}
              closeLightbox={closeLightbox}
              lightLink= {portfolioArr.link}
            />
          }
        <section className='pt100 relative z-10'>
            <div className="container">
                <ScrollAnimation animateIn='backInLeft' animateOnce={true} >
                <span  className='block text-center fs20 text-[#1D2C38] font-bold mb-3'>Our Recent Work</span>
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInRight' animateOnce={true} >
                <h2  className='text-center text-[#1D2C38] fs45 font-bold'>Checkout <span className='text-[#04B0B0]'> Our Portfolio </span></h2>
                </ScrollAnimation>
                
                
                <div className='flex justify-between flex-wrap gap-y-14 py80 min-[1690px]:mb-0 mb-10 max-[1500px]:gap-y-12 max-[1370px]:gap-y-9'>
                    {
                        portfolioArr.map((portfolioData, index) => (
                            <ScrollAnimation key={index} className={`portfolioBox ${portfolioData.isWhite ? 'border-[1px] border-[#a6a7ab] rounded-[20px]' : ''}`} animateIn={`${portfolioData.animate}`}>
                                <img src={portfolioData.imgSrc} alt="Portfolio project at Pixel Pace Technologies" className='portfolioImg' />
                                <img src={portfolioData.imgSrcShadow} alt="Portfolio project at Pixel Pace Technologies" className='portfolioShadow' />
                                <img src={zoomIcon} alt="Zoom In" className='zoomIcon' onClick={() => handleZoomIconClick(portfolioData.imgSrc)} />
                                <a href={portfolioData.link} className='flex gap-1 items-center previewIcon' target='_blank' rel="noopener noreferrer">
                                    <p className='text-white fs28 font-medium'>Preview</p>
                                    <img src={previewIcon} className='max-[1370px]:w-[20px]' alt="Preview Icon" />
                                </a>
                            </ScrollAnimation>
                        ))
                    }
                </div>
                <ScrollAnimation animateIn='backInDown' animateOnce={true} >
                {
                    isSpecificPage ?
                        <Button shadowClr='rgba(0,0,0,0.15)' btnTxt="Book a Discovery Call" btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" btnIcon={<PhoneIcon iconClr='#fff' className='w-[28px] max-[1680px]:w-[24px] max-[1500px]:w-[20px] max-[1370px]:w-[15px]' />} className={`${btnWithPort} before:bg-black`} />
                        : <Link to='/Portfolio'>
                            <Button shadowClr='rgba(0,0,0,0.75)' btnTxt="View More Portfolio" btnIcon={<PortfolioArrow iconClr='#fff' className='w-[28px] max-[1680px]:w-[24px] max-[1500px]:w-[20px] max-[1370px]:w-[15px]' />} className={`${btnWithoutPort} before:bg-black`} />
                        </Link>
                }
                </ScrollAnimation>
                

            </div>
        </section>
        </>
    )
}

export default PortfolioWrap
