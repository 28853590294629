import React, { useState, useRef } from 'react'
import Slider from "react-slick";
import './testimonial.scss'
import ScrollAnimation from 'react-animate-on-scroll';
const testimonialArr = [
    {
        imgSrc: require("../../../assets/images/testimonail1.png"),
        alt: "Jonathan Kirschner - Happy client | Pixel Pace Technologies",
    },
    {
        imgSrc: require("../../../assets/images/testimonail2.png"),
        alt: "Sarah Anderson - Happy client | Pixel Pace Technologies",
    },
    {
        imgSrc: require("../../../assets/images/testimonail3.png"),
        alt: "Daniel Martinez - Happy client | Pixel Pace Technologies",
    },
    {
        imgSrc: require("../../../assets/images/testimonail4.png"),
        alt: "William Collins - Happy client | Pixel Pace Technologies",
    },
    {
        imgSrc: require("../../../assets/images/testimonail5.png"),
        alt: "Olivia Turner - Happy client | Pixel Pace Technologies",
    },
    {
        imgSrc: require("../../../assets/images/testimonail1.png"),
        alt: "Jonathan Kirschner - Happy client | Pixel Pace Technologies",
    },
    {
        imgSrc: require("../../../assets/images/testimonail2.png"),
        alt: "Sarah Anderson - Happy client | Pixel Pace Technologies",
    },
    {
        imgSrc: require("../../../assets/images/testimonail3.png"),
        alt: "Daniel Martinez - Happy client | Pixel Pace Technologies",
    },
    {
        imgSrc: require("../../../assets/images/testimonail4.png"),
        alt: "William Collins - Happy client | Pixel Pace Technologies",
    },
    {
        imgSrc: require("../../../assets/images/testimonail5.png"),
        alt: "Olivia Turner - Happy client | Pixel Pace Technologies",
    },
]
const testimonialArr2 = [
    {
        title: "Jonathan Kirschner",
        detail: ' “Thank you Pixel Pace Technologies! Your service allowed our team to stay focused on our business as they executed on website tasks- critical and small.” ',
    },
    {
        title: "Sarah Anderson",
        detail: " “Pixel Pace Technologies has been a true partner in our success. Their service has allowed us to remain focused on our business while they efficiently manage all website-related tasks. We can't recommend them enough for their outstanding support!” ",
    },
    {
        title: "Daniel Martinez",
        detail: " “A huge thank you to Pixel Pace Technologies for their exceptional service. Their dedication to handling all our website tasks, big and small, has made a significant difference in our operations. We are extremely satisfied with their professionalism and expertise!” ",
    },
    {
        title: "William Collins",
        detail: " “Pixel Pace Technologies has exceeded our expectations. Their service has been a blessing, taking the weight of website management off our shoulders. Our team can now channel their energy into our business's growth, all thanks to their exceptional support.” ",
    },
    {
        title: "Olivia Turner",
        detail: " “We can't thank Pixel Pace Technologies enough for their outstanding service. Their expertise in managing our website tasks has been a tremendous help, allowing us to stay laser-focused on our core business objectives. A true asset to any company!” ",
    },
    {
        title: "Jonathan Kirschner",
        detail: ' “Thank you Pixel Pace Technologies! Your service allowed our team to stay focused on our business as they executed on website tasks- critical and small.” ',
    },
    {
        title: "Sarah Anderson",
        detail: " “Pixel Pace Technologies has been a true partner in our success. Their service has allowed us to remain focused on our business while they efficiently manage all website-related tasks. We can't recommend them enough for their outstanding support!” ",
    },
    {
        title: "Daniel Martinez",
        detail: " “A huge thank you to Pixel Pace Technologies for their exceptional service. Their dedication to handling all our website tasks, big and small, has made a significant difference in our operations. We are extremely satisfied with their professionalism and expertise!” ",
    },
    {
        title: "William Collins",
        detail: " “Pixel Pace Technologies has exceeded our expectations. Their service has been a blessing, taking the weight of website management off our shoulders. Our team can now channel their energy into our business's growth, all thanks to their exceptional support.” ",
    },
    {
        title: "Olivia Turner",
        detail: " “We can't thank Pixel Pace Technologies enough for their outstanding service. Their expertise in managing our website tasks has been a tremendous help, allowing us to stay laser-focused on our core business objectives. A true asset to any company!” ",
    },
   
]
const Testimonial = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slider1Ref = useRef(null);
    const slider2Ref = useRef(null);

    const settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        // centerPadding:'50px',
        arrows: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    centerMode: false,
                }
            },
        ],
        afterChange: (current) => {
            // When the first slider changes, update the currentSlide state
            setCurrentSlide(current);

            // Move the second slider to the same slide
            slider2Ref.current.slickGoTo(current);
        },
    };
    const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        ref: slider2Ref,
        arrows: false,
    };
    return (
        <div className='py100 text-center testimonialwrap'>
            <div className="container">
                <ScrollAnimation animateIn='backInLeft' animateOnce={true} >
                <span className='block text-[#04B0B0] fs20 font-semibold mb-2'>Our Clients</span>
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInRight' animateOnce={true} >
                <h2 className='text-[#24323D] fs45 font-extrabold  mb-3'>Hear from Our Clients at Pixel Pace Technologies</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInUp' animateOnce={true} >
                <p className='xl:w-[85%] w-full mx-auto mb-20 max-[1680px]:mb-8 leading-normal'>
                Discover the impact of our expert web design and digital marketing services through the voices of those who know us best—our clients. Our testimonials showcase the confidence and satisfaction our clients experience with our tailored solutions:
                </p>
                </ScrollAnimation>
                <ScrollAnimation className='testimonialSlider' animateIn='backInUp' animateOnce={true} >
                <Slider className='py-14 max-[575px]:py-8' {...settings1} ref={slider1Ref} >
                    {
                        testimonialArr.map((testimonialData, index) => (
                            <img key={index} src={testimonialData.imgSrc} alt={testimonialData.alt}  />
                        ))
                    }
                </Slider>
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInDown' animateOnce={true} >
                <Slider className='pt80' {...settings2}>
                    {
                        testimonialArr2.map((testimonialData, index) => (
                            <div   key={index} className='max-w-[850px] w-full mx-auto'>
                                <p   className='fs20 font-medium text-black mb-5 max-[1370px]:mb-3  max-[575px]:mb-1'>{testimonialData.detail}</p>
                                <p   className='fs18 font-bold text-[#1D2C38]'>{testimonialData.title}</p>
                            </div>
                        ))
                    }
                </Slider>
                </ScrollAnimation>
                
<ScrollAnimation animateIn='backInDown' animateOnce={true} >
<p className='md:mt-4 mt-3 leading-normal'>
                <b>Why Choose Us?</b> Our clients appreciate our dedicated approach to delivering comprehensive digital solutions that not only meet but exceed their expectations. From small startups to large corporations, Pixel Pace Technologies is here to ensure your digital success with proven results.
                </p>
</ScrollAnimation>
                
            </div>
        </div>
    )
}

export default Testimonial
