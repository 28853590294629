import React from 'react';
import Button from '../../components/button/Button';
import DiscoveryIcon from '../../assets/images/DiscoveryIcon';
import ScrollAnimation from 'react-animate-on-scroll';
const DiscoveryCall = () => {

    return (
        <div className='bg-[#006363] py80'>
            <div className="container text-center text-white">
                <ScrollAnimation animateIn='backInLeft' animateOnce={true}  >
                <DiscoveryIcon className='w-[105px] mx-auto max-[1500px]:w-[90px] max-[1370px]:w-[80px] max-[991px]:w-[70px]' />
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInRight' animateOnce={true} >
                <h2 className='mt-[20px] fs32 font-semibold max-[1370px]:mt-[10px'>Not Ready to Get Started?</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInDown' animateOnce={true} >
                <p className='my-[20px] max-w-[500px] w-full mx-auto max-[1370px]:my-[16px]'>
                    Book a call with our experts and find out if we are a great match for each other.
                </p>
                </ScrollAnimation>
                <ScrollAnimation animateIn='backInUp' animateOnce={true}  className='w-fit mx-auto' >
                    <Button
                        btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer"
                        btnTxt='Book a Discovery Call'
                        className={`bg-black w-fit md:py-5 py-4 fs18 px-[35px] mx-auto max-[1370px]:px-[25px] before:bg-[#0FE3E3]`} shadowClr='rgba(0,0,0,0.5)' />
                </ScrollAnimation>

            </div>
        </div>
    );
};

export default DiscoveryCall;
