import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/UxUiDesBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';

const dataArr = [
  {
    subtitle:"Focus on User Satisfaction:",
    title:"Our designs prioritize user engagement and satisfaction, enhancing the overall user experience with intuitive navigation and aesthetically pleasing visuals.",
  },
  {
    subtitle:"Customized Design Solutions:",
    title:"Each project is tailored to fit the unique needs of our clients, ensuring that every design element works seamlessly within the larger context of their business goals.",
  },
  {
    subtitle:"Data-Driven Design Decisions:",
    title:"Leveraging the latest user research and analytics, we make informed design decisions that improve usability and functionality.",
  },
]

const UxUiDes = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});
  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  return (
    <div className="serviceBg py100" style={serviceBgStyle}>
      <div className="container">
        <BrowserTabTitle tabTitle={'Professional UI/UX Design Services | Pixel Pace Technologies'} metaDescription={`Enhance your user experience with Pixel Pace Technologies. Our UI/UX design services focus on creating seamless, intuitive interfaces that ensure user satisfaction and drive engagement. Discover our approach to transforming your digital interfaces into captivating experiences. Book a consultation today!`} />

        <ServicePage title="UI/UX Design Excellence at Pixel Pace Technologies" subTitle='Creating Seamless User Experiences' detail="At Pixel Pace Technologies, we understand that the cornerstone of successful digital products lies in their user experience. Our UI/UX design services are dedicated to crafting interfaces that are not only visually stunning but also intuitive and enjoyable, ensuring they meet the high expectations of today's users." />
        <div className='pt100' ref={ref}>
        <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__backInLeft' : ''}`}>Why Our UI/UX Design Services Stand Out:</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__backInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default UxUiDes
