import React from 'react';
import HomeBanner from './homeBanner/HomeBanner'
import AboutUS from './aboutUs/AboutUS'
import ServicesBox from './servicesSec/ServicesBox'
import BookAppoinment from './BookAppoinment'
import PortfolioWrap from './portfolioWrap/PortfolioWrap'
import TrustedClient from './trustedClient/TrustedClient'
import Quote from './Quote'
import Testimonial from './testimonial/Testimonial'
import WorkProcess from './workProcess/WorkProcess'
import ContactUs from '../contactUS/ContactUs'
import BrowserTabTitle from '../../components/browserTabTitle';

const IndexPage = () => {

    return (
        <div>
            <BrowserTabTitle tabTitle={'Expert Website Design & Digital Marketing Services | Pixel Pace Technologies'} metaDescription={`Discover Pixel Pace Technologies for expert website design and digital marketing strategies to elevate your brand online. Our innovative solutions drive business growth—reach out today for a consultation!`} />
            <HomeBanner />
            <AboutUS />
            <ServicesBox />
            <BookAppoinment />
            <PortfolioWrap />
            <TrustedClient />
            <Quote />
            <Testimonial />
            <WorkProcess />
            <ContactUs />
        </div>
    )
}

export default IndexPage
