import React from 'react'
import Slider from "react-slick";
import './trustedClient.scss'
import ScrollAnimation from 'react-animate-on-scroll';
const trustArr = [
  {
    clientImg: require("../../../assets/images/client1.png")
  },
  {
    clientImg: require("../../../assets/images/client2.png")
  },
  {
    clientImg: require("../../../assets/images/client3.png")
  },
  {
    clientImg: require("../../../assets/images/client4.png")
  },
  {
    clientImg: require("../../../assets/images/client5.png")
  },
  {
    clientImg: require("../../../assets/images/client6.png")
  },
  {
    clientImg: require("../../../assets/images/client1.png")
  },
  {
    clientImg: require("../../../assets/images/client2.png")
  },
  {
    clientImg: require("../../../assets/images/client3.png")
  },
  {
    clientImg: require("../../../assets/images/client4.png")
  },
  {
    clientImg: require("../../../assets/images/client5.png")
  },
  {
    clientImg: require("../../../assets/images/client6.png")
  },

]
const TrustedClient = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
          breakpoint: 575,
          settings: {
              arrows: false,
              slidesToShow: 3,
          }
      },
  ],
  };
  return (
    <div className='text-center clientBg pb-40 max-[1500px]:pb-28 max-[991px]:pb-20'>
      <div className="container">
        <ScrollAnimation animateIn='backInLeft' animateOnce={true} >
        <span className='block text-[#1D2C38] fs20 font-semibold mb-1'>Our Clients</span>
        </ScrollAnimation>
        <ScrollAnimation animateIn='backInRight' animateOnce={true} >
        <h2 className='text-[#1D2C38] fs45 font-bold'>Worldwide <span className='text-[#04B0B0]'> Trusted Clients </span></h2>
        </ScrollAnimation>
<ScrollAnimation animateIn='backInUp' animateOnce={true} >
<Slider  {...settings} className='xl:w-[80%] mx-auto mt-20 max-[991px]:mt-10 max-[575px]:mt-6'>
          {
            trustArr.map((trustData, index) => (
              <img src={trustData.clientImg} alt="Trusted Clients at Pixel Pace Technologies" className='max-w-[114px] w-full max-[991px]:max-w-[75px]' key={index}   />
            ))
          }
        </Slider>
</ScrollAnimation>
       
      </div>
    </div>
  )
}

export default TrustedClient
