import React, { useState, useEffect } from 'react'
import logoWhite from '../../assets/images/PP_logoWhite.svg'
import logoBlack from '../../assets/images/PP_logoBlack.svg'
import Button from '../../components/button/Button'
import PhoneIcon from '../../assets/images/PhoneIcon'
import CrossIcon from '../../assets/images/CrossIcon'
import { Link, useLocation, useNavigate } from "react-router-dom"
import './navbar.scss'

const navLiArr = [
    {
        navLiTxt: "Home",
        link: "/",
    },
    {
        navLiTxt: "Services",
        link: "/Services",
        dropArr: [
            {
                title: 'Web Development',
                link: '/web-development'
            },
            {
                title: 'Web Design',
                link: '/web-design'
            },
            {
                title: 'Digital Marketing',
                link: '/marketing'
            },
            {
                title: 'UI/UX Design',
                link: '/ux-ui-design'
            },
            {
                title: 'WordPress',
                link: '/wordpress'
            },
            {
                title: 'Shopify',
                link: '/shopify'
            },
            {
                title: 'Mobile Application',
                link: '/mob-app'
            },
        ]

    },
    {
        navLiTxt: "Pricing",
        link: "/Pricing"
    },
    {
        navLiTxt: "Portfolio",
        link: "/Portfolio"
    },
    {
        navLiTxt: "Contact",
        link: "/ContactUs"
    },

]

const Navbar = ({ isScrolled, isMenuOpen, setIsMenuOpen, hamclick }) => {
    const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
    const [isNavSec, setIsNavSec] = useState(false);
    const [isNavPrice, setIsNavPrice] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [openDropdownOnClick, setOpenDropdownOnClick] = useState(screenWidth < 1000);
    const Navigate = useNavigate();
    const [isNavbarFixed, setIsNavbarFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsNavbarFixed(window.scrollY > 38);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    

    const handleMainlogo = () => {
        Navigate('/');
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    const navbarClass = isScrolled ? 'bg-white fixed w-full top-0 shadow py-4 z-50 max-[1370px]:py-3' : 'relative pt-8 max-[1680px]:pt-6 max-[1500px]:pt-4 max-[1370px]:pt-3 max-[575px]:pt-4';
    const navLinks_ = isMenuOpen ? 'text-[#fff]' : isScrolled ? 'text-[#000]' : isNavSec ? 'text-[#fff]' : 'text-[#000]';
    const text = isServicesDropdownOpen ? "text-[#0FE3E3]" : navLinks_;
    const navUl = isMenuOpen ? 'max-[991px]:left-[0px] before:max-h-[100%]' : 'max-[991px]:left-[-280px] before:max-h-[0%]';
    const navPricing = isNavPrice ? 'bg-black pricingNav' : isScrolled

    const handleNavLink = (route) => {
        setActiveLink(route);
        setIsMenuOpen(false);
    }

    const handleScreenWidthChange = () => {
        setScreenWidth(window.innerWidth);
        setOpenDropdownOnClick(window.innerWidth < 1000);
    };


    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    useEffect(() => {
        if (location.pathname === '/') {
            setIsNavSec(true);
        }
        else {
            setIsNavSec(false)
        }
        if (location.pathname === '/Pricing') {
            setIsNavPrice(true);
            setIsNavSec(true)
        }
        else {
            setIsNavPrice(false)
        }
        setActiveLink(location.pathname);

        // Listen for screen width changes
        window.addEventListener('resize', handleScreenWidthChange);

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('resize', handleScreenWidthChange);
        };

    }, [location.pathname]);

    const handleServicesMouseEnter = () => {
        if (!openDropdownOnClick) {
            setIsServicesDropdownOpen(true);
        }
    };

    const handleServicesMouseLeave = () => {
        if (!openDropdownOnClick) {
            setIsServicesDropdownOpen(false);
        }
    };

    const handleServicesClick = () => {
        if (openDropdownOnClick) {
            setIsServicesDropdownOpen(!isServicesDropdownOpen);
        }
    };

    const dropLiOff = () => {
        setIsMenuOpen(false);
        setIsServicesDropdownOpen(false);
    };


    return (
        <nav id='navBarId' className={`transition-all duration-300 ease-linear ${navbarClass} ${navPricing} ${isNavbarFixed ? 'fixed' : 'relative'}`}>
            <div className="container flex justify-between items-center">
                <div className="max-w-[194px] w-full max-[1680px]:max-w-[150px] max-[1500px]:max-w-[125px] max-[1370px]:max-w-[100px] max-[575px]:max-w-[85px] cursor-pointer animate__animated animate__backInLeft" onClick={handleMainlogo}>
                    <Link to="/">
                        <img src={`${location.pathname === '/' || location.pathname === '/Pricing' ? (isScrolled ? logoBlack : logoWhite) : logoBlack}`} alt="Pixel Pace Technologies | Website Design & Digital Marketing Services" />
                    </Link>
                </div>
                <div className="w-full lg:hidden flex justify-end mr-4">
                    <Button shadowClr='rgba(0, 0, 0, 0.15)' btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" btnTxt="Consultation Call" className="navBtn bg-[#CAFFFF] w-fit px-4 py-2 text-[#000] fs18 max-[575px]:px-2 max-[575px]:gap-1" btnIcon={<PhoneIcon iconClr="#000" className='w-[16px] max-[1370px]:w-[8px]' />} />
                </div>
                <div id="hamburgerButton" className={`w-[24px] h-[14px] flex-col justify-between hidden max-[991px]:flex`} onClick={hamclick}>
                    <span className={`h-[1px] w-full block ${isScrolled ? 'bg-black'
                        : isNavSec ? 'bg-white' : 'bg-black'}`}></span>
                    <span className={`h-[1px] w-full block ${isScrolled ? 'bg-black'
                        : isNavSec ? 'bg-white' : 'bg-black'}`}></span>
                    <span className={`h-[1px] w-full block ${isScrolled ? 'bg-black'
                        : isNavSec ? 'bg-white' : 'bg-black'}`}></span>
                </div>
                <div className={`flex lg:w-fit lg:flex-row flex-col md:w-[280px] lg:h-auto h-full max-[991px]:fixed lg:bg-transparent bg-black lg:items-center items-start max-[991px]:top-0 lg:justify-end ms-auto slideIn max-[991px]:w-[230px] max-[991px]:z-50 ${navUl}`}>
                    <ul id='navBarInner' className='relative flex lg:justify-between justify-start w-full lg:items-center items-start lg:flex-row flex-col min-[1370px]:gap-8 gap-6 lg:p-0 px-[15px] md:py-[35px] lg:h-auto h-full py-[20px]'>
                        <li  className='hidden max-[991px]:block max-w-[120px] mb-4 max-[575px]:max-w-[95px] cursor-pointer' onClick={handleMainlogo}>
                            <img src={logoWhite} alt="Pixel Pace Technologies | Website Design & Digital Marketing Services" />
                        </li>
                        <li  className='hidden max-[991px]:block max-w-[120px] absolute top-[15px] right-[15px]' onClick={(() => (setIsMenuOpen(false)))}>
                            <CrossIcon className='w-[20px] max-[575px]:w-[15px]' iconClr='#fff' />
                        </li>
                        {navLiArr.map((navLiData, index) => (
                            <li  key={index} className='animate__animated animate__backInRight'>
                                {navLiData.navLiTxt === 'Services' ? (
                                    <div
                                        onMouseEnter={handleServicesMouseEnter}
                                        onMouseLeave={handleServicesMouseLeave}
                                        onClick={handleServicesClick}
                                        className={`relative ${openDropdownOnClick ? 'dropdown-click' : 'dropdown-hover'}`}
                                    >
                                        <Link
                                            className={`fs20 cursor-pointer font-medium flex gap-[7px] leading-loose items-center  ${activeLink === navLiData.link ? 'activeNav' : ''
                                                } ${text}`}
                                        >
                                            {navLiData.navLiTxt}
                                            <i className="fs16 fas fa-chevron-down"></i>
                                        </Link>
                                        <div
                                            className={`absolute max-[991px]:relative bg-[#CAFFFF] rounded shadow dropdown max-[991px]:bg-transparent ${isServicesDropdownOpen ? 'dropdown-entered' : 'dropdown-entering'
                                                }`}
                                        >
                                            {
                                                navLiData.dropArr.map((dropData, index) => (
                                                    <Link key={index} className='fs20cursor-pointer lg:text-black text-white font-semibold  hover:bg-[#000] hover:text-[#0FE3E3]' onClick={dropLiOff} to={dropData.link}>{dropData.title} </Link>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <Link
                                        to={navLiData.link}
                                        className={`fs20 cursor-pointer font-medium hover:text-[#0FE3E3] ${activeLink === navLiData.link ? 'activeNav' : ''
                                            } ${navLinks_}`}
                                        onClick={() => handleNavLink(navLiData.link)}
                                    >
                                        {navLiData.navLiTxt}
                                    </Link>
                                )}
                            </li>
                        ))}
                        <li className='animate__animated animate__backInRight'>
                            <Button shadowClr='rgba(0, 0, 0, 0.15)' btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" btnTxt="Free Consultation Call" className="navBtn bg-[#CAFFFF] w-fit min-[1570px]:p-4 px-4 py-3  text-[#000] fs18 max-[991px]:mt-4" btnIcon={<PhoneIcon iconClr="#000" className='w-[16px] max-[1370px]:w-[12px]' />} />
                        </li>
                    </ul>

                </div>

            </div>
        </nav>
    )
}

export default Navbar