import React from 'react'
import portfolioBanner from '../../assets/images/portfolioBanner.png'
import Button from '../../components/button/Button'
import ScrollAnimation from 'react-animate-on-scroll'

const dataArr = [
  {
    subtitle: "Expertise in Modern Technologies:",
    title: "We harness the latest technologies to create scalable and impactful digital solutions.",
  },
  {
    subtitle: "Customized Strategies:",
    title: "Tailored approaches designed to meet specific business needs, ensuring optimal outcomes.",
  },
  {
    subtitle: "Proven Track Record:",
    title: "With a history of successful projects, we are trusted by businesses across various industries.",
  },
]
const PortfolioBanner = () => {
  return (
    <div className='py100'>
      <div className="container">
        <div className='flex justify-between max-[991px]:flex-wrap max-[991px]:justify-center max-[991px]:gap-6 items-center'>
        <div className='xl:w-[50%] w-full animate__animated animate__backInLeft'>
          <h1 className='fs45 text-[#1D2C38] font-bold'>Exploring <span className='text-[#04B0B0]'> Our Portfolio </span></h1>
          <p className='fs24 text-[#302F2F] font-regular leading-relaxed my-10 max-[1680px]:my-6 max-[1370px]:my-4 max-[991px]:my-3'>At Pixel Pace Technologies, we see software development outsourcing as more than just a strategic tool; it's a partnership crafted to achieve your business goals. Journey with us towards tangible results, rooted in mutual trust and collaboration.</p>
          <p className='fs24 text-[#302F2F] font-regular leading-relaxed my-10 max-[1680px]:my-6 max-[1370px]:my-4 max-[991px]:my-3'>Join us as we walk you through a curated showcase of our successful projects in website design and digital marketing. Each project illustrates our commitment to excellence and client satisfaction.</p>
          {/* <Button btnTxt='Learn More About Us' className='max-w-[330px] w-full h-[85px] bg-[#04B0B0] rounded-[10px] fs22 font-semibold text-white max-[1570px]:max-w-[250px] max-[1570px]:h-[70px] max-[1370px]:max-w-[205px] max-[1370px]:h-[55px] max-[575px]:h-[45px] max-[575px]:max-w-[185px]' /> */}
        </div>
        <div className='xl:w-[45%] w-full animate__animated animate__backInRight'>
          <img src={portfolioBanner} alt="" />
        </div>
        </div>

        <div className='pt100'>
          <ScrollAnimation animateIn='backInLeft' animateOnce={true} >
            <h2 className='fs40 mb-4'>Why Choose Us?</h2>
          </ScrollAnimation>
          {
            dataArr.map((data, index) => (
              <ScrollAnimation animateIn='backInUp' animateOnce={true}  key={index} className='mb-3 last-of-type:mb-0'>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </ScrollAnimation>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default PortfolioBanner
