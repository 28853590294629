import React from 'react'
import contactImg from '../../assets/images/contactUS.png'
import './contactUS.scss'
import Button from '../../components/button/Button'
import PhoneIcon from '../../assets/images/PhoneIcon'
import ScrollAnimation from 'react-animate-on-scroll';
import { useInView } from 'react-intersection-observer';

const ContactUs = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <section className='py100'>
        <div className="container" ref={ref}>
            <span className={`block text-[#04B0B0] fs20 font-semibold mb-2 text-center ${inView ? 'animate__animated animate__backInLeft' : ''}`}>Contact Us</span>
            <h2 className={`text-[#24323D] fs40 font-extrabold pb80 text-center ${inView ? 'animate__animated animate__backInRight' : ''}`}>Contact to Discuss your Project</h2>
            
            <div className='bg-[#EBFFFF] flex gap-8 items-center w-full rounded-[10px] py-8 px-10   max-[1560px]:px-6 max-[1560px]:py-6 max-[1370px]:p-4 max-[991px]:mx-auto md:flex-nowrap flex-wrap overflow-hidden'>
                <div className={`max-w-[462px] w-full max-[1560px]:max-w-[420px] max-[1500px]:max-w-[350px] max-[1370px]:max-w-[290px] max-[991px]:max-w-[260px] max-[575px]:max-w-[100%] ${inView ? 'animate__animated animate__backInLeft' : ''}`}>
                    <img src={contactImg} alt="Team of Pixel Pace Technologies" className='w-full' />
                </div>
                <div className='flex flex-col gap-2'>
                    <h3 className={`text-[#1E2125] fs28 font-bold ${inView ? 'animate__animated animate__backInUp' : ''}`}>Connect with Pixel Pace Technologies</h3>
                    <p className={`text-[#3C3E41] fs18 font-normal ${inView ? 'animate__animated animate__backInUp' : ''}`}>Discover the benefits of expert web design and digital marketing services by getting in touch with our team. We're based in Melbourne, Australia, and are eager to help you enhance your digital presence. Whether you're local or global, our door is open for collaboration. Here's how you can reach us:</p>
                    <a href="tel:+61 417 205 778" className={`text-[#3C3E41] fs18 font-normal mb-4 max-[1500px]:mb-2 max-[1370px]:mb-1 ${inView ? 'animate__animated animate__backInUp' : ''}`}>Phone: +61 417 205 778</a>
                    <a href="mailto:info@pixelpacetechnologies.com"   className={`text-[#3C3E41] fs18 font-normal mb-4 max-[1500px]:mb-2 max-[1370px]:mb-1 ${inView ? 'animate__animated animate__backInUp' : ''}`}>Email: info@pixelpacetechnologies.com</a>
                    <p className={`text-[#3C3E41] fs18 font-normal ${inView ? 'animate__animated animate__backInUp' : ''}`}>Location: Melbourne, Australia</p>
                    <Button btnLink="https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today" target='_blank' rel="noreferrer" shadowClr='#04B0B0' btnTxt="Book a Discovery Call" btnIcon={<PhoneIcon iconClr='#fff' className='w-[24px] max-[1500px]:w-[18px] max-[1370px]:w-[15px] max-[992px]:w-[14px] max-[575px]:w-[12px]' />} className={`bg-[#006363] min-[1570px]:py-6 min-[1570px]:px-8 min-[1370px]:py-5 min-[1370px]:px-6 py-4 px-4  w-fit mt-4 fs22 text-[#fff] gap-6 font-semibold max-[1680px]:gap-4 max-[1500px]:gap-3 max-[1370px]:gap-2 before:bg-black ${inView ? 'animate__animated animate__backInUp' : ''}`} />
                </div>
            </div>
        </div>
    </section>
    )
}

export default ContactUs
